import "./styles.less";

import React, { useContext } from "react";

import { Radio, Select } from "antd";
import { useOperationType } from "./OperationType.hook";
import { useTheme } from "../../../Styles/ThemeHook";
import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import { IC_Collapse } from "shared-components/Components/Collapse/web";

const { Option } = Select;

export const OperationType = ({ collapsable = false, home = false, ...props }) => {
	const { theme } = useTheme();
	const { country_code } = useContext(ConfigStateContext);
	const isCeee = country_code === "ce3";
	const {
		show,
		error,
		loading,
		inputType,
		label,
		labeled,
		data: { options, value, onChange, customKey },
	} = useOperationType(props);
	const { showSeasonInput, handleChangeSeason } = props;

	if (!show || error) return null;

	const opts = options ?? [];
	const optsSeason = [
		{
			value: 0,
			title: "Buscar por Calendario"
		},
		{
			value: 1,
			title: "Buscar por Temporada"
		}
	]

	const handleChange = e => {
		let val = e;
		const res = options.filter(o => o[customKey] == val);
		onChange(res[0]);
	};


	const filterRadio = (
		<Radio.Group onChange={e => handleChange(e.target.value)} value={value} disabled={loading}>
			{opts.map(o => (
				<Radio value={o[customKey]} key={`key_${o[customKey]}_operationType`}>{o.name}</Radio>
			))}
		</Radio.Group>
	);

	const filterButtons = (
		isCeee ?  (
			<Radio.Group
				defaultValue={Number(showSeasonInput)}
				buttonStyle="solid"
				optionType="button"
				onChange={() => handleChangeSeason(!showSeasonInput)}
			>
				{optsSeason.map((option, index) => (
					<Radio.Button key={`button-season-${index}`} value={option.value}>{option.title}</Radio.Button>
				))}
			</Radio.Group>
		) : (
			<Radio.Group
				disabled={loading}
				onChange={e => handleChange(e.target.value)}
				optionType="button"
				buttonStyle={"solid"}
				value={value}>
				{opts.map(o => (
					<Radio.Button
						key={`key_buttons_${o[customKey]}_operationType`}
						value={o[customKey]}>
						{o.name}
					</Radio.Button>
				))}
			</Radio.Group>
		)
	);

	const filterSelect = (
		<Select
			disabled={loading}
				defaultValue={loading ? "Operación" : isCeee ? Number(showSeasonInput) : value}
				onChange={(e) => isCeee ? handleChangeSeason(!showSeasonInput) : handleChange(e)}
		>
			{isCeee
			? optsSeason.map((option, index) => (
				<Option key={`option-season-${index}`} value={option.value}>
					{option.title}
				</Option>
			))
			: opts.map(o => (
				<Option key={`key_select_${o[customKey]}_operationType`} value={o[customKey]}>
					{o.name}
				</Option>
			))}
		</Select>
	);

	let filter = filterButtons;
	if (inputType == "radioselect") filter = filterRadio;
	else if (inputType == "select") filter = filterSelect;

	return (
		<div className="filter operation-type-filter">
			{collapsable 
				? <IC_Collapse label="Tipo de Operación" isActive={value}>
					{filter}
				</IC_Collapse>
				: filter
			}
			
		</div>
	);
};

import { FragmentDefiner, useReadFragment } from "../../../GlobalHooks/useReadFragment";
import { isTemporal, propertiesTypeInTemporal } from "../../../Utils/Functions";

import { useFilters } from "../Filters.hook";

export const FRAGMENT_PROPERTYTYPES_OPTIONS = new FragmentDefiner(
	"Filter",
	`
		id
		name
		options
  `
);
export const FRAGMENT_PROPERTYTYPES = new FragmentDefiner(
	"Filters",
	`
		propertyType {
		  ${FRAGMENT_PROPERTYTYPES_OPTIONS.query()}
		}
  `
);

const usePropertyType = props => {
	const { filters, changeFilters } = useFilters();
	const {
		labeled = false,
		selectedValue = filters?.property_type_id,
		inputType = "select",
		currentFilters = filters,
		filterChanged = changeFilters,
	} = props;

	const { loading, data, error } = useReadFragment(
		FRAGMENT_PROPERTYTYPES_OPTIONS,
		"propertyType"
	);

	const onChange = newValue => {
		let res = [];
		if (newValue != null) {
			res = newValue.map(v => {
				return { value: v["property_type_id"], text: v["name"] };
			});
		}
		filterChanged({ property_type_id: res });
	};

	const type = "multiple";

	const dataOptions = isTemporal(filters.operation_type_id)
		? data?.options.filter(e => propertiesTypeInTemporal(e.property_type_id))
		: data?.options;

	return {
		loading: loading,
		data: {
			options: dataOptions,
			onChange,
			value: selectedValue,
			type: type,
			customKey: "property_type_id",
		},
		error: error,
		show: true,
		labeled,
		label: data?.name,
		inputType,
	};
};

export { usePropertyType };

import { useState } from "react"
import { DownOutlined } from "@ant-design/icons"

export const IC_Collapse = ({startOpen=false, label='', children, isActive=false}) => {
	const [open, setOpen] = useState(startOpen)

	return <>
		<div className="ant-collapse-header" style={{display:"flex", alignItems:"center"}} onClick={()=>{setOpen(!open)}}>
				
				{isActive && 
					<span style={{backgroundColor: '#007882', marginRight: "15px",borderRadius: '50%', display: 'inline-block', height: '12px', width: '12px',}}></span>
				}

				<span style={{flex:"1 0 auto"}}>{label}</span>
				<DownOutlined rotate={open ? 180 : 0} style={{marginRight:'3px'}}/>
				
			</div>
			<div className={`ant-collapse-content ${open? 'collapse-active' : 'collapse-inactive'}`} style={{border:"none"}}>
				<div className="ant-collapse-content-box">
					{children}
				</div>
			</div>

		<style jsx>{`
			.ant-collapse-header{
				cursor: pointer;
			}
			.collapse-inactive{ 
				max-height: 0px;
				transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
			}
			.collapse-active{
				max-height: 100vh;
				transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
			}
		`}</style>
	</>
}
import { FragmentDefiner, useReadFragment } from "../../../GlobalHooks/useReadFragment";
import { useFilters } from "../Filters.hook";

const FRAGMENT_OPERATIONTYPES_OPTIONS = new FragmentDefiner(
	"Filter",
	`
      id
      name
      options
`
);
export const FRAGMENT_OPERATIONTYPES = new FragmentDefiner(
	"Filters",
	`
    operationType {
      ${FRAGMENT_OPERATIONTYPES_OPTIONS.query()}
    }
`
);

const useOperationType = props => {
	const { filters, changeFilters } = useFilters();
	const {
		labeled = false,
		selectedValue = filters?.operation_type_id,
		inputType = "select",
		currentFilters = filters,
		filterChanged = changeFilters,
	} = props;
	const { loading, data, error } = useReadFragment(
		FRAGMENT_OPERATIONTYPES_OPTIONS,
		"operationType"
	);

	const onChange = newOperation => {
		filterChanged({
			operation_type_id: newOperation
				? { value: newOperation.operation_type_id, text: newOperation.name }
				: null,
		});
	};

	return {
		loading: loading,
		error: error,
		data: {
			options: data?.options,
			value: selectedValue,
			onChange,
			customKey: "operation_type_id",
		},
		show: true,
		labeled,
		label: data?.name,
		inputType,
	};
};

export { useOperationType };

import "./styles.less";

import React, { useContext } from "react";

import { Button, Typography } from "antd";
import { CalendarFilled, ContactsFilled } from "@ant-design/icons";

import { DateRange } from "./DateRange/web";
import { Seasons } from "./Seasons/web";
import { useTemporalFilter } from "./TemporalFilter.hook";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { IC_Collapse } from "shared-components/Components/Collapse/web";


export const TemporalFilter = ({
	filterChanged,
	labeled = false,
	collapsable = false,
	isInFilters = false,
	showSeasonInput = false,
	handleChangeSeason
}) => {
	const {
		isTemporal,
		filters: { dateTo, dateFrom, season },
	} = useTemporalFilter();
	const { country_code } = useContext(ConfigStateContext);

	if (!isTemporal) return null;


	const filter = (
		<>
			{showSeasonInput ? (
				<Seasons filterChanged={filterChanged} />
			) : (
				<DateRange filterChanged={filterChanged} />
			)}
			{(isInFilters || country_code !== "ce3") && (
				<Button
					className="btn-search-seasons"
					type="text"
					onClick={() => handleChangeSeason(!showSeasonInput)}
					icon={showSeasonInput ? <CalendarFilled /> : <ContactsFilled />}
				>
					<Typography.Text>
						{showSeasonInput ? "Buscar por Calendario" : "Buscar por Temporada"}
					</Typography.Text>
				</Button>
			)}
		</>
	);

	return (
		<div className="filter temporal-filter">
			{collapsable 
				? <IC_Collapse label="Temporada / Fechas" isActive={dateFrom || dateTo || season}>
					{filter}
				</IC_Collapse>
				: filter
			}
		</div>
	);
};
